<script setup>
    const props = defineProps({
        filter: { type: Object, required: false, },
    });
</script>

<template>
    <div class="d-flex justify-content-between align-items-center" v-if="filter">
        <button class="btn ps-0 pb-0 d-flex justify-content-center align-items-center" @click="filter.showOrderSheet = !filter.showOrderSheet;">
            <i class="far fa-sort-alt" style="font-size: 1.25em;"></i> <small class="ms-2 fw-medium">{{ filter.orders[filter.storage.order].name }}</small>
        </button>

        <button class="btn pb-0" @click="filter.showFieldSheet = !filter.showFieldSheet">
            <i class="far fa-sliders-h" style="font-size: 1.25em;"></i>
        </button>
    </div>
</template>
